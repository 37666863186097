import { graphql } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef } from 'react'
import Fade from 'react-reveal/Fade'
import tw, { css, theme } from 'twin.macro'
import ArticleCarousel from '../../components/article-carousel'
import Button from '../../components/button'
import HeaderBlock from '../../components/header-block'
import Heading from '../../components/heading'
import HeroAnimation from '../../components/hero-animation'
import Layout from '../../components/layout'
import LogoGrid from '../../components/logo-grid'
import { overlays } from '../../components/overlays/index'
import Statistics from '../../components/statistics'
import Text from '../../components/text'
import UseCaseCards from '../../components/use-case-cards'
import VideoHero from '../../components/video-hero'
import VideoPlayer from '../../components/video-player'
import PatternMesh from '../../images/pattern-mesh.svg'
import InsurtechJson from '../../images/insurtech.json'
import { global } from '../../styles/global'
import { min } from '../../utils/breakpoints'
import { flatten } from '../../utils/data'

const IndexPage = ({ data: { index } }) => {
  const page = useMemo(
    () =>
      flatten(index || {}, [
        'heroVideo',
        'insuranceBanner',
        'introduction',
        'insurtechIntroduction',
        'useCasesHeader',
        'providersheader',
        'missionVideo',
        'missionCta',
      ]),
    [index]
  )

  const bannerRef = useRef(null)

  useEffect(() => {
    const query = `(min-width: ${theme`screens.lg`})`
    ScrollTrigger.matchMedia({
      [query]: () => {
        gsap.to(bannerRef.current, {
          y: -150,
          ease: 'none',
          scrollTrigger: {
            trigger: '#hero',
            start: 'top top+=80px',
            end: 'bottom top+=80px',
            scrub: true,
          },
        })
      },
    })
  }, [])

  return (
    <Layout
      pageTags={page.seoMetaTags}
      noIndex={page.noIndex}
      headerTransition={true}
      headerTransitionColor="white"
    >
      <section id="hero" css={tw`relative`}>
        <VideoHero
          videoUrl={get(page, 'heroVideo.video.url')}
          title={get(page, 'heroVideo.titleNode')}
          description={get(page, 'heroVideo.descriptionNode')}
          callToAction={get(page, 'heroVideo.callToAction')}
          useCarousel
        />
        {overlays.hero}
        <div
          ref={bannerRef}
          css={[
            tw`box-border	absolute bottom-2 right-2 flex flex-col items-center flex-col-reverse flex-col-reverse w-5/6 max-w-xs
            lg:(grid absolute inset-0 bottom-32)`,
            global`layout.desktop-container`,
            global`layout.desktop-grid`,
          ]}
        >
          <aside
            id="insurance-banner"
            css={tw`bg-accent-500 z-1 relative col-start-1 col-end-7 self-end p-10 w-full flex flex-col items-start
            md:(col-end-9) lg:(col-start-9 col-end-13) xl:(col-start-13 col-end-17)`}
          >
            <Text
              content={get(page, 'insuranceBanner.subtitleNode')}
              style={tw`font-medium leading-8`}
              textType="body-lg"
            />
            <Heading
              content={get(page, 'insuranceBanner.titleNode')}
              headingType="h4"
              style={tw`mt-2 font-bold`}
            />
            <Button
              type="primary"
              size="sm"
              theme="navy-cyan"
              label={get(page, 'insuranceBanner.callToAction.label')}
              link={get(page, 'insuranceBanner.callToAction.link')}
              disabled={get(page, 'insuranceBanner.callToAction.disabled')}
              style={tw`mt-10`}
            />
          </aside>
        </div>
      </section>
      <section id="intro" css={tw`relative bg-primary-500`}>
        <div css={[global`layout.container`, global`layout.grid`, tw`py-20 lg:(py-0)`]}>
          <div css={tw`col-span-4 md:col-span-8 lg:(py-36 col-span-12) xl:col-span-16`}>
            <div css={tw`flex flex-col lg:(w-2/3 mx-auto items-center justify-center) xl:w-1/2`}>
              <Heading
                headingType="h3"
                content={get(page, 'introduction.titleNode')}
                style={tw`text-white lg:text-center`}
              />
              <Text
                style={tw`text-white my-16 lg:(mt-6 mb-28 text-center text-opacity-50)`}
                content={get(page, 'introduction.descriptionNode')}
              />
            </div>
            <div css={tw`col-span-4 md:col-span-8 lg:col-span-12 xl:col-span-16`}>
              <Statistics statistics={get(page, 'statistics')} />
            </div>
          </div>
        </div>
      </section>
      <section id="insurtech" css={tw`relative bg-background-grey-light lg:-mb-9`}>
        <div
          css={[
            global`layout.container`,
            global`layout.grid`,
            tw`py-24 lg:(items-center pt-32 pb-48)`,
          ]}
        >
          <div css={tw`col-span-4 md:col-span-8 lg:(col-span-6) xl:(col-span-7)`}>
            <Text
              content={get(page, 'insurtechIntroduction.subtitleNode')}
              style={tw`font-bold text-secondary-500 lg:mb-16`}
              textType="body-md"
            />
            <Heading
              content={get(page, 'insurtechIntroduction.title')}
              style={tw`font-bold text-primary-500`}
              headingType="h2"
            />
            <Text content={get(page, 'insurtechIntroduction.descriptionNode')} style={tw`py-6`} />
            <Button
              style={tw`mt-10 w-fit-content lg:mt-12`}
              type="primary"
              size="sm"
              theme="navy-cyan"
              label={get(page, 'insurtechIntroduction.callToAction.label')}
              link={get(page, 'insurtechIntroduction.callToAction.link')}
              disabled={get(page, 'insurtechIntroduction.callToAction.disabled')}
            />
          </div>
          <div
            css={tw`relative col-span-4 md:col-span-8 lg:col-span-6 xl:(col-start-9 col-end-17)`}
          >
            <div css={tw`pt-24 lg:(pt-0)`}>
            <Fade bottom delay={500}>
              <HeroAnimation animationData={InsurtechJson} />
            </Fade>
            </div>
          </div>
        </div>
      </section>
      <section id="products" css={tw`relative pt-24 pb-12 lg:(pt-40 pb-28)`}>
        <div css={[global`layout.container`, global`layout.grid`]}>
          <div css={tw`col-span-4 z-1 md:col-span-8 lg:col-span-12 xl:col-span-16`}>
            <div css={tw`flex flex-col items-center justify-center lg:(w-2/3 mx-auto)`}>
              <Heading
                headingType="h2"
                content={get(page, 'useCasesHeader.title')}
                style={tw`font-bold text-center text-primary-500`}
              />
            </div>
            <div css={tw`flex flex-col items-center justify-center mt-12 lg:(mt-6 w-1/2 mx-auto)`}>
              <Text
                content={get(page, 'useCasesHeader.descriptionNode')}
                css={tw`font-normal text-center text-primary-500`}
                textType="body-lg"
              />
            </div>
          </div>
          <div css={tw`col-span-4 z-1 mt-28 md:col-span-8 lg:(col-span-12 mt-46) xl:col-span-16`}>
            <UseCaseCards useCases={get(page, 'useCases')} />
          </div>
        </div>
        <div
          css={css`
            ${tw`absolute bottom-0 left-0 right-0 h-full bg-bottom`}
            background-image: url('${PatternMesh}');
          `}
        />
      </section>
      <section id="providers" css={tw`py-24 pb-0 bg-white lg:pt-40`}>
        <div css={[global`layout.container`, tw`flex justify-center`]}>
          <HeaderBlock
            title={get(page, 'providersheader.title')}
            description={get(page, 'providersheader.descriptionNode')}
            subtitle={get(page, 'providersheader.subtitleNode')}
            descriptionType="h6"
            style={css`
              ${tw`items-center text-center lg:(w-7/10)`}
              h6 {
                ${tw`font-normal`}
              }
            `}
          />
        </div>
        <LogoGrid
          logos={get(page, 'providers')}
          style={[tw`mx-container-mobile`, global`layout.desktop-container`]}
        />
      </section>
      <section
        id="mission"
        css={tw`mr-container-mobile relative z-10 flex flex-col mt-10 mb-0 lg:(flex-row mt-32 mr-0)`}
      >
        <div
          css={tw`pt-24 pb-28 px-6 mr-6 bg-secondary-500 lg:(flex-1 py-32 ml-10 pl-14 pr-36 mr-0) xl:(py-48 ml-12 pl-20 pr-52) xxl:(py-64 ml-20 pl-28 pr-72)`}
        >
          <Heading
            headingType="h2"
            content={get(page, 'missionCta.titleNode')}
            style={css`
              ${tw`font-medium text-primary-500`}
              strong {
                ${tw`font-medium`}
              }
            `}
          />
          <Button
            style={tw`mt-10 w-fit-content lg:mt-12`}
            type="primary"
            size="base"
            theme="navy-yellow"
            label={get(page, 'missionCta.callToAction.label')}
            link={get(page, 'missionCta.callToAction.link')}
            disabled={get(page, 'missionCta.callToAction.disabled')}
          />
        </div>
        <div
          css={[
            tw`ml-container-mobile -mt-16 lg:(self-end flex-1 pb-14 mt-0)`,
            css`
              ${min.lg} {
                margin-left: -10vw;
              }
            `,
          ]}
        >
          <VideoPlayer
            streamingUrl={get(page, 'missionVideo.video.video.mp4Url')}
            thumbnailUrl={get(page, 'missionVideo.poster.url')}
            style={css`
              padding-top: 75%;
            `}
          />
        </div>
      </section>
      <section
        id="insights"
        css={tw`bg-grey-50 relative py-24 overflow-hidden lg:(pt-58 pb-42 -mt-32)`}
      >
        <div css={[global`layout.container`, tw`lg:(flex flex-row items-center justify-between)`]}>
          <Heading
            content={get(page, 'insightTitle')}
            headingType="h3"
            style={tw`font-bold text-primary-500`}
          />
          <Button
            type="primary"
            size="sm"
            style={tw`hidden opacity-50 lg:flex`}
            theme="transparent-transparent"
            label={get(page, 'insightCta.label')}
            link={get(page, 'insightCta.link')}
          />
        </div>
        <ArticleCarousel
          cards={get(page, 'insightsList')}
          style={tw`mt-16 mb-20 lg:(mt-20 mb-0)`}
        />
        <div css={[global`layout.container`]}>
          <Button
            type="primary"
            size="sm"
            style={css`
              ${tw`opacity-50 lg:hidden`}
              span {
                ${tw`ml-0`}
              }
            `}
            theme="transparent-transparent"
            label={get(page, 'insightCta.label')}
            link={get(page, 'insightCta.link')}
          />
        </div>
      </section>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    index: PropTypes.object.isRequired,
  }),
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    index: datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      heroVideo {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        video {
          format
          url
        }
        mobileVideo {
          format
          url
        }
      }
      insuranceBanner {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      noticeNode {
        childMarkdownRemark {
          html
        }
      }
      introduction {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          link
          label
          disabled
        }
      }
      statistics {
        labelNode {
          childMarkdownRemark {
            html
          }
        }
        value
      }
      missionVideo {
        video {
          video {
            thumbnailUrl(format: gif)
            streamingUrl
            mp4Url
          }
        }
        poster {
          format
          url
        }
      }
      missionCta {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          link
          label
          disabled
        }
      }
      insurtechIntroduction {
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          link
          label
          disabled
        }
      }
      insurtechImage {
        format
        url
      }
      useCasesHeader {
        title
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          link
          label
          disabled
        }
      }
      useCases {
        image {
          format
          url
        }
        title
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          link
          label
          disabled
        }
      }
      providersheader {
        title
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      providers {
        format
        url
      }
      insightTitle
      insightCta {
        link
        label
        disabled
      }
      insightsList {
        slug
        typeOfArticle
        headline
        date
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
      }
    }
  }
`
